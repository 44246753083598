#blackFriday{
    color: #fff;
    background-color: black;
    padding-bottom: 1rem;
}

.bgblack{
    background-color: #cccaca;
    color: black;
    border-radius: 15px;
}

.black button {
    background-color: rgb(4, 0, 255);
    padding: 1rem;;
    border: 0;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1rem;
}

.black button a {
    list-style: none;
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
}

.black button:hover {
    background-color: #000;
    color: #ff0000; /* Vermelho */
}

.black button:hover a {
    color: #ff0000; /* Vermelho durante o hover */
}