#headbandBlack {
    
    padding: 3rem;
    background-image: url("../../../assets/BLACK_FRIDAY_BACKGROUND.png");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    
}



 #headbandBlack .container h1{
    font-family: 'Roboto', sans-serif;
    font-size: 62px;
    color: #ffffff;
  
}

#headbandBlack .container span{
    font-family: 'Roboto', sans-serif;
    font-size: 82px;
    color: #8d0000;
    text-shadow: 3px 3px 4px #ffffff;
    
}



.box-pBlack{
    width: 50%;
    text-align: justify;
    color: #fff;
}

