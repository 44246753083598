.btncontato{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}

.btncontato button{
    padding: 1rem;
    border-radius: 10px;
    border: 0;
    background-color: #004A99;

}

.btncontato button a{
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    
}