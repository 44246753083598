* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* BG HEADER */
.floating-component {
    position: fixed;
    /* Define a posição fixa para que o componente permaneça na tela */
    top: 20%;
    /* Ajusta a posição vertical para o centro da tela (opcional) */
    right: 20px;
    /* Ajusta a posição horizontal para o lado direito da tela */
    background-color: #007BFF;
    color: #000000;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* Controla a ordem de sobreposição (deixe-o acima do conteúdo principal) */
    width: 80px;
    height: 350px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.lig {
    font-size: 12px;
    text-align: center;
}

.lig a {
    text-decoration: none;
    color: #000;
}

.jivo {
    font-size: 12px;
    text-align: center;
}

.jivo a {
    text-decoration: none;
    color: #000;
}

.whats {
    font-size: 12px;
    text-align: center;
}

.whats a {
    text-decoration: none;
    color: #000;
}

#close-button {
    background-color: #ff0000;
    color: #fff;
    border: none;
    padding: 2px;
    cursor: pointer;
    margin-top: -10px;
    position: fixed;
    right: 20px;
}

#close-button:hover {
    background-color: #ff6666;
}

.rs{
    width: 100%;
    margin-top: 1px;
}

.bg {
    background: url("../../assets/circuito.gif") no-repeat center;
    /* background: url("../../assets/Site.jpg") no-repeat center; */
    background-size: cover;
    
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.bg h1 {
    font-size: 4rem;
    color: #ffffff;
    /* Cor do texto */
    -webkit-text-stroke: 2px #000000;
    /* Contorno do texto (largura e cor) */
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
    /* Sombra do texto (opcional) */
}

.bg h2 {
    background-color: #007BFF;
    padding: 0.5rem;
    width: 43%;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgb(0, 0, 0);
}

.bg span {
    background-color: #000000;
    padding: 0.5rem;
}

.bg a {
    list-style: none;
    text-decoration: none;
    background-color: #25d366;
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 10px;
}

.a {
    margin-top: 15px;
}

.dados {
    /* margin-top: 15px; */
    padding: 1rem;
    background-color: #000000;
    color: #ffffff;
}

/* Estilize conforme suas necessidades, como cor e tamanho de fonte */
#contador1,
#contador2,
#contador3,
#contador4 {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}

/* Inicialmente, oculte os números */
#contador1:before,
#contador2:before,
#contador3:before,
#contador4:before {
    content: "0";
    display: block;
    visibility: hidden;
}


.txt-align {
    text-align: center;
}

.barra {
    margin-top: 3rem;
    padding: 1rem 2rem;
    background-color: #004A99;
    height: 220px;

}

.barra h3 {
    color: white;
}

.barra span {
    color:rgb(255, 255, 255);
}

.barra p {
    font-size: 12px;
    color: white;
}

.barra button {
    background-color: rgb(4, 0, 255);
    padding: 0.5rem;
    border: 0;
    border-radius: 10px;
    margin-bottom: 2rem;
    
}

.barra button a {
    list-style: none;
    text-decoration: none;
    font-weight: 500;
    color: #fff;
}

.align-top {
    margin-top: -60px;
    /* Ajuste o valor negativo para mover a imagem para cima */
}

.roww{
    display: flex;
    align-items: center;
    justify-content: center;
}





/* FIM BG HEADER */

/* Inicio Bem Vindos */

.bemvindo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    flex-direction: column;
}

.titulo {
    margin-bottom: 3rem;
    text-align: center;
}

.cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
}

.cont1 {
    padding: 0 3rem;
    font-style: italic;
}

.align {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Fim Inicio Bem Vindos */

/* PQ ESCOLHER */
.pqescolher {
    background: #004A99;
    padding: 2rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.box {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin-top: 0.5rem;

}

.box1 {
    width: 300px;
    height: 300px;
    background-color: #003B7A;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.box1 p {

    text-align: center;


}

/* CLIENTES */

.clientes {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1rem 0; */
    flex-direction: column;
    background: #c3dcf8;
    padding: 1rem;
    padding-bottom: 2rem;

}

/* FAIXA */

.faixa {

    background: url("../../assets/faixa.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 205px;
    color: #ffffff;
    /* Cor do texto */
    -webkit-text-stroke: 1px #000000;
    /* Contorno do texto (largura e cor) */
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
    /* Sombra do texto (opcional) */
}

.colfaixa {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.h1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* SOLUÇÔES  */
.soluções {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/Tech.png") center, #004a9925;
    color: #000000;
    /* margin: 1rem 0; */
    padding: 1rem;

}

.titulo2 {

    text-align: center;
}

.solutions {
    /* #c3dcf8 */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.boxlg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.cx1 {
    width: 250px;
    height: 250px;
    margin: 5px;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    transition: 0.3s;
}

.cx1:hover {
    transform: scale(1.11);
    /* Aumenta o tamanho em 20% */
    border: 1px solid #000;
    border-radius: 15px;
    z-index: 1;
    cursor: pointer;
    color: #ffffff;

}


.cx1 p {
    font-size: 12px;
    text-align: justify;
    color: #535252;
}

.cx1 h5 {

    color: #000000;
}

@media (max-width: 890px) {
    .barra {
        margin-top: 1rem;
        padding: 0.1rem ;
        height: 100%;
    
    }

    .roww{
        flex-direction: column;
    }

    .colum{
        text-align: center;
    }
    
    .barra img{
        width: 250px;
        margin-top: 0;
        
    }

}

@media (max-width: 770px) {
    .barra {
        margin-top: 1rem;
        padding: 1rem 2rem;
        background-color: #0d9fe2;
        height: 100%;
    
    }
    
    .barra img{
        display: none;
    }

    .faixa img{
        display: none;
    }
   
    
    .boxlg {
        flex-direction: column;
    }
    
   
}

@media (max-width: 490px) {
    .pqescolher {
        padding: 1rem;
        text-align: center;
    }
    
    .box {
        
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    
    
}

@media (max-width: 290px) {
    
}