.countdown {
    text-align: center;
    padding: 10px;
    background-color: #6b0202;
}

.countdown h3{
    color: #fff;
}

.bf p {
    font-family: 'Roboto', sans-serif;
    font-size: 42px;
    /* Ajuste o tamanho do contador conforme necessário */
    margin-bottom: 5px;
    /* Adiciona um espaço abaixo do contador */
    color: #fff;
    text-shadow: 4px 4px 4px #000000;
}

.bf span {
    font-size: 18px;
    /* Ajuste o tamanho do "seg" conforme necessário */
    display: block;
    /* Faz com que o "seg" apareça em uma nova linha */
    margin-top: -15px;
    /* Ajuste para subir mais o "seg" */
    color: #fff;
    text-shadow: 4px 4px 4px #000000;
}