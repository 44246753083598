/* Painel.css */


#painel h1, h2 {
    color: #343a40;
    text-align: center;
}

#painel h1 {
    margin-bottom: 20px;
}

#painel h2 {
    margin-bottom: 10px;
    color: #007bff;
}

#painel form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    max-width: 500px;
    margin: 0 auto;
}

#painel input {
    margin: 10px 0;
    padding: 12px;
    width: 100%;
    max-width: 450px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}
#painel textarea {
    margin: 10px 0;
    padding: 12px;
    width: 100%;
    max-width: 450px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

#painel button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    max-width: 450px;
}

#painel button:hover {
    background-color: #0056b3;
}

#painel ul {
    padding: 0;
    list-style-type: none;
    margin-top: 30px;
}

#painel li {
    background-color: #ffffff;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#painel li p {
    margin: 0;
    color: #666;
    font-size: 14px;
}

#painel button {
    background-color: #007bff;
    padding: 6px 12px;
    font-size: 14px;
    margin: 5px;
    transition: background-color 0.2s ease;
    width: 50%;
}

#painel button:hover {
    background-color: #007bff;
}

#painel button.delete {
    background-color:#007bff;
}

#painel button.delete:hover {
    background-color: #007bff;
}
.linha{
    display: flex;
}

@media (max-width: 768px) {
    form {
        max-width: 100%;
    }

    button {
        max-width: 100%;
    }

    li {
        flex-direction: column;
        align-items: flex-start;
    }
}
