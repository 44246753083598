.slide{
    max-width: 700px;
    min-height: 50vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.inner{
    display: flex;
}

.item{
   min-height: 200px;
   min-width: 300px;
    padding: 14px;
}

.item img{
    width: 100%;
    height: 90%;
    border-radius: 12px;
    pointer-events: none;
}

.carousel{
    cursor: grab;
    overflow: hidden;

}