#footer {
    background-color: #004A99;
    color: #fff;
    padding: 2rem;
}

#footer ul {
    list-style: none;

}

#footer ul li {
    margin: 5px;


}

#footer ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 12px;
}

#footer ul li a:hover {
    text-decoration: underline
}

.lista {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.redessociais>* {
    margin: 0 10px;
    /* Define uma margem à esquerda e à direita de 10 pixels para os ícones */
}

.align1{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    font-size: 12px;
}

.boxfooter2 {
    background-color: #003B7A;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.boxfooter2 a {
    text-decoration:underline;
    color: #fff;
}

@media (max-width: 890px) {
    #footer ul li a {
       font-size: 10px;
    }
    .align1{
        font-size: 10px;
    }
    .lista ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}

@media (max-width: 770px) {
    
       
}

@media (max-width: 490px) {
    
    
        
}

@media (max-width: 290px) {
    
}