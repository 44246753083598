/* .form_contato {
    padding: 2rem;
    
} */

.form1 {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
    
    
}

.form1 input{
    width: 100%;
    border-radius: 10px;
    margin: 2px;
    border: 0.5px solid blue;
    padding: 0.3rem;

}



.form2 {
    
   
    
}

.form2 textarea{
  
    width: 100%;
    border-radius: 10px;
    margin: 2px;
    border: 0.5px solid blue;
    padding: 0.3rem;
    margin-top: 1rem;

}

.divi button{
    padding: 5px;
    border: 0;
    background-color: #0D6EFD;
    color:azure;
    width: 200px;
    margin: 5px;
    border-radius: 15px;
}

.divi{
    display: flex;
    justify-content: flex-end;
}